@import '@/_styles/import';
$height: 61px;

.heightPlaceholder {
  height: $height;
}

:global(.PromoBannerPlace):has(.root) {
  // Safari fix: makes `absolute` positioning work
  container-type: unset;
}

.root {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  overflow: hidden;

  width: 100vw;
  height: $height;

  color: $purple;

  background-color: $pale-green;

  a {
    :global(.Countdown) {
      cursor: pointer;
    }
  }
}

.content {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;

  width: fit-content;
}

.eyebrow {
  white-space: nowrap;
}

.title,
.description {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.4px;
  white-space: nowrap;
}

.title {
  margin: 0;
  font-weight: 700;
}

.description {
  font-weight: 400;
}

.icon {
  &:global(.Icon) {
    width: 20px;
    height: 20px;
    color: $vv-red;

    svg {
      display: block;
    }
  }
}
